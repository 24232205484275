<template>
    <div class="app-container">
        <div class="head-title">
            <p class="txt"></p>
            <p class="desc-title">{{$t("emergencyContact.txt3")}}</p>
        </div>
        <div class="yzm-container">
            <div class="yzm-section">
                <van-field v-model="yzmTxt" type="number" :placeholder='$t("emergencyContact.placeholder")' />
                <p class="submit" @click="submit">马上提交</p>
                <div class="hr-box"></div>
                <div class="tips">
                    <p class="title">温馨提示</p>
                    <p class="txt">验证码在医院给您发送的短信中，注意查看</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { groupChatJoin } from "@/api/index"

export default {
    data () {
        return {
            yzmTxt:"",
            chatId:this.$route.query.chatId
        }
    },
    created () {
        if(sessionStorage.getItem('emergencyContact_yzm')){
            this.yzmTxt = sessionStorage.getItem('emergencyContact_yzm');
        }
    },
    methods:{
        submit(){
            if(!this.yzmTxt){
                this.$toast(this.$t("emergencyContact.placeholder"))
                return
            }
            sessionStorage.setItem("emergencyContact_yzm",this.yzmTxt+'')
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            this.$imapiAxio.post('/im/group-chat-join',{
                securityCode:this.yzmTxt,
                chatId:this.chatId
            }).then(res =>{
                toast.clear();
                console.log(res);
                if(res.errorCode == 0){
                    this.$router.push({
                        path:"/im/chat",
                        query:{
                            chatId:res.data
                        }
                    })
                }else{
                    this.$toast(res.errorMsg)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.app-container{
    height: 100%;
    overflow: auto;
    background: linear-gradient(180deg, #49A7FF 0%, #5CD2FF 99%);
    .head-title{
        margin: 1.6rem 1.2rem 0 1.2rem;
        .txt{
            width: 7.44rem;
            height: 1.6rem;
            background: url("~@/assets/img/title-banner.png") no-repeat;
            background-size: 100% 100%;
            
            // font-size: 1.44rem;
            // font-family: PangMenZhengDao;
            // font-weight: 600;
            // font-style: italic;
            // width: max-content;
            // // color: #FFFFFF;
            // line-height: 1.44rem;
            // //  -webkit-text-stroke: 1px #fff;
            // // text-shadow: 0 0.01rem 0.1rem #fff;
            // // background: linear-gradient(180deg, #FFFFFF 0%, #D7F4FF 53.1005859375%);
            // // -webkit-background-clip: text;
            // // -webkit-text-fill-color: transparent;
            
            // background-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#d7f4ff), to(#fff));
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // text-shadow: 0.02rem 0.02rem 0.02rem #fff;
            // position: relative;
            // z-index: 1;
            // &::after{
            //     z-index: -1;
            //     content: '紧急联系人';
            //     position: absolute;
            //     top: 0.04rem;
            //     width: -webkit-max-content;
            //     width: -moz-max-content;
            //     width: max-content;
            //     left: 0.04rem;
            // }
        }
        .desc-title{
            height: 1.16rem;
            width: max-content;
            margin-top: .56rem;
            margin-bottom: .86rem;
            font-size: 0.64rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FEFEFE;
            line-height: 1.16rem;
            background: linear-gradient(90deg, #49A7FF 0%, #79CEFA 49%, #49A7FF 100%);
        }
    }
    .yzm-container{
        margin: 0  0.6rem 1rem 0.6rem;
        padding: 0.4rem 0.6rem 0 0.6rem;
        position: relative;
        &::before{
            content: ' ';
            width: 100%;
            height: 0.92rem;
            left: 0;
            top: 0;
            position: absolute;
            background: url("~@/assets/img/yzm-container-back1.png") no-repeat;
            background-size: 100% 100%;
        }
        &::after{
            position: absolute;
            width: 4.22rem;
            height: 4.72rem;
            content: ' ';
            background: url("~@/assets/img/yzm-container-back2.png") no-repeat;
            background-size: 100% 100%;
            top: -4.72rem;
            right: 0.76rem;
        }
        .yzm-section{
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 0.6rem 2rem 0.6rem;
            box-shadow: 0 0.12rem 0.28rem 0 rgba(35,125,210,0.3500);
            border-radius: 0 0 0.4rem 0.4rem;
            position: relative;
            z-index: 1;
            background-color: #FFFFFF;
            /deep/ .van-field{
                margin: 1.5rem 0;
                line-height: 1.8rem;
                text-align: center;
                height: 1.8rem;
                padding: 0;
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 400;
                border: 0.04rem solid #ECEEF3;
                border-radius: 0.9rem;
                .van-field__control{
                    text-align: center;
                }
            }
            .submit{
                height: 1.8rem;
                line-height: 1.8rem;
                text-align: center;
                background: linear-gradient(0deg, #0095FF 0%, #6EC9FF 100%);
                border-radius: 0.9rem;
                font-size: 0.72rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
            }
            .hr-box{
                width: 10.8rem;
                border-bottom: 0.06rem dashed #AEB7B9;
                margin: 3rem auto 1rem auto;
                position: relative;
                &::after,&::before{
                    width: 0.6rem;
                    height: 0.6rem;
                    border-radius: 50%;
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0,-50%);
                    left: -11%;
                    background: #53beff;
                }
                &::before{
                    left: auto;
                    right: -11%;
                }
            }
            .tips{
                .title{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 0.2rem;
                    position: relative;
                    z-index: 1;
                    width: max-content;
                    &::after{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        left: 0;
                        z-index: -1;
                        content: ' ';
                        height: 0.46rem;
                        background: linear-gradient(90deg, #90F1FF 0%, #FFFFFF 100%);
                    }
                }
                .txt{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
}
</style>